import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { Router, useMatch } from '@reach/router';
import { Link } from 'gatsby';
import { useQuery } from '@apollo/client';
import ErrorLine from '../containers/errorLine';
import SEO from '../seo';
import DesignCard from './designCard';
import { AbilityContext } from '../../auth/abilityContext';
import { UserContext } from '../../auth/protectedArea';
import AddDesignCard from './addDesignCard';
import Design from './design';
import AddDesign from './addDesign';
import * as requests from './requests';
import PropertySelect from '../inputs/propertySelect';

const DesignsList = ({ propertiesList, startpageTemplatesList }) => {
  const [filteredList, setFilteredList] = useState(startpageTemplatesList);

  const pIdSet = new Set(startpageTemplatesList.flatMap(x => x.propertyIds));
  const requiredPropertyList = propertiesList.filter(x => pIdSet.has(x.id));

  const filterByProperty = propertyId => {
    setFilteredList(
      startpageTemplatesList.filter(x => x.propertyIds.includes(propertyId)),
    );
  };
  const clear = () => {
    setFilteredList(startpageTemplatesList);
  };

  return (
    <>
      <PropertySelect
        onSelect={filterByProperty}
        propertyList={requiredPropertyList}
        direction="down"
        clear={clear}
      />
      <div className="row" style={{ marginBottom: '-1px' }}>
        {filteredList.map(template => {
          return (
            <DesignCard
              template={template}
              propertiesList={propertiesList}
              to={`/designs/${template.id}`}
              key={`design-${template.id}`}
            />
          );
        })}
        <AddDesignCard />
      </div>
    </>
  );
};

const DesignsContainer = () => {
  const title = 'Designs';
  const baseRoute = '/designs';
  const ability = useContext(AbilityContext);
  const { userId } = useContext(UserContext);
  const canLoadAll = ability.can('load', 'PropertiesList');
  const getPropertiesQuery = canLoadAll
    ? requests.GET_TEMPLATES
    : requests.GET_USER_TEMPLATES;
  const getPropertiesOptions = canLoadAll ? {} : { variables: { userId } };

  const { loading, error, data, refetch } = useQuery(getPropertiesQuery, {
    ...getPropertiesOptions,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const unpackUserProperties = userProperties =>
    userProperties.map(({ ...userProperty }) => ({
      ...userProperty.property,
    }));

  let propertiesList;
  if (data) {
    propertiesList = canLoadAll
      ? data.propertiesList
      : unpackUserProperties(data.usersPropertiesList || []);
  }

  const [limitedContainer, setLimitedContainer] = useState(false);

  // Set limited container
  const match = useMatch(`${baseRoute}/:id`);
  useEffect(() => {
    if (
      !limitedContainer &&
      match &&
      data &&
      (data.startpageTemplatesList.find(s => s.id === +match.id) ||
        match.id === 'add')
    ) {
      setLimitedContainer(true);
    }
    if (!match) setLimitedContainer(false);
  }, [match, limitedContainer, data]);

  // Refetch data
  const [designsUpdated, setDesignsUpdated] = useState(false);
  const match2 = useMatch(baseRoute);
  useEffect(() => {
    if (match2 && !match && !designsUpdated) {
      setDesignsUpdated(async () => {
        await refetch();
        return true;
      });
    }
    if (!match2) setDesignsUpdated(false);
  }, [match2, designsUpdated, match, refetch]);

  return (
    <div className="pt-4">
      <SEO title={title} />
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && data.startpageTemplatesList && (
        <div className={`${limitedContainer ? 'limited-container' : ''}`}>
          <div className="row px-4 border-bottom">
            <Link to={baseRoute} className="navbar-brand text-dark">
              <h3>{title}</h3>
            </Link>
          </div>
          <Router basepath={baseRoute}>
            <DesignsList
              propertiesList={propertiesList}
              startpageTemplatesList={data.startpageTemplatesList}
              default
            />
            <AddDesign
              path="/add"
              baseRoute={baseRoute}
              propertiesList={propertiesList}
            />
            {data.startpageTemplatesList.map(template => {
              return (
                <Design
                  path={`/${template.id}`}
                  template={template}
                  propertiesList={propertiesList}
                  baseRoute={baseRoute}
                  key={`design-${template.id}`}
                />
              );
            })}
          </Router>
        </div>
      )}
    </div>
  );
};

export default DesignsContainer;
