import React from 'react';
import { ModalDialog } from './containers/dialog';
import { Button } from 'reactstrap';
import { Can } from '../auth/abilityContext';

const DeleteButton = ({
  subjectName,
  deleteFunction,
  deletingStatus,
  button,
  block,
}) => (
  <Can I="delete" a={subjectName}>
    <ModalDialog
      buttonLabel="Delete"
      buttonColor="danger"
      title="Delete item"
      block={block}
      footer={
        button || (
          <Button onClick={deleteFunction} color="danger">
            Delete
          </Button>
        )
      }
    >
      Are you sure?
      {deletingStatus}
    </ModalDialog>
  </Can>
);
export default DeleteButton;
