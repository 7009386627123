import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useMutation, useQuery } from '@apollo/client';
import { Spinner } from 'reactstrap';
import FormWrapper from '../../form/formWrapper';
import ErrorLine from '../containers/errorLine';
import { schema, uiSchema } from '../../schemas/design';
import * as requests from './requests';

const Design = ({ startpageTemplate, propertiesList, baseRoute }) => {
  const subjectName = 'UpdateDesign';
  const propertyUrlTemplate = 'HOTEL_*_TOKEN'; // * - propertyId
  const { id: templateId, template, propertyIds, pageTag } = startpageTemplate;

  const selectedProperties = propertiesList.filter(p => {
    return propertyIds.includes(p.id);
  });
  const keywords = selectedProperties.map(p => ({
    title: p.name,
    value: propertyUrlTemplate.split('*').join(p.id),
  }));

  const formData = {
    template,
    properties: propertyIds.join(','),
    pageTag,
  };

  const [complete, setComplete] = useState(null);
  const [formKey, setFormKey] = useState(1);

  const [updateTemplate, { loading: loading1, error: error1 }] = useMutation(
    requests.UPDATE_STARTPAGE_TEMPLATE,
    {
      onCompleted: () => {
        setComplete(true);
        setFormKey(formKey + 1);
      },
    },
  );

  const [deleteTemplate, { loading: loading2, error: error2 }] = useMutation(
    requests.DELETE_STARTPAGE_TEMPLATE,
    {
      onCompleted: () => {
        navigate(baseRoute, { replace: true });
      },
    },
  );

  const onSubmit = data => {
    updateTemplate({
      variables: {
        templateId,
        template: data.template,
        // pageTag: data.pageTag,
      },
    });
  };

  const onDelete = () => {
    deleteTemplate({ variables: { templateId } });
  };

  const loading = loading1 || loading2;
  const error = error1 || error2;
  const success = complete && !loading && !error;

  return (
    <FormWrapper
      formData={formData}
      subjectName={subjectName}
      loading={loading}
      schemaSource={schema}
      uiSchemaSource={uiSchema}
      onSubmit={onSubmit}
      formKey={formKey}
      formContext={{
        loading,
        success,
        error,
        propertiesList,
        keywords,
        onDelete,
      }}
      children={' '}
    />
  );
};

const DesignContainer = ({ template, propertiesList, baseRoute }) => {
  const { id: templateId } = template;

  const { data, loading, error } = useQuery(requests.GET_STARTPAGE_TEMPLATE, {
    variables: { templateId },
    fetchPolicy: 'network-only',
  });

  return (
    <div className="px-2 py-4">
      {loading && <Spinner color="secondary" />}
      {error && <ErrorLine error={error} />}
      {data && (
        <Design
          startpageTemplate={data.startpageTemplate}
          propertiesList={propertiesList}
          baseRoute={baseRoute}
        />
      )}
    </div>
  );
};

export default DesignContainer;
