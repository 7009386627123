import React from 'react';
import { Link } from 'gatsby';
import PropertyLogo from '../propertyLogo';
import '../../styles/sectionCard.css';
import { UncontrolledTooltip } from 'reactstrap';

const DesignCard = ({ template, propertiesList, to }) => {
  const propertiesOfTemplate = propertiesList.filter(p => {
    return template.propertyIds.includes(p.id);
  });
  return (
    <div className="section-card col-sd-12 col-md-6 col-lg-4 col-xl-3 p-0">
      <Link to={to}>
        <div className="property-section-card-body border-right border-bottom d-flex flex-column text-sm-left p-3">
          {/*TODO: add design name*/}
          <div className="section-card-top d-flex flex-row">
            <h5 className="section-card-title p-1 m-0">{`Start page template - ${template.id}`}</h5>
            <p className="section-card-tag">
              <code>{template.pageTag}</code>
            </p>
          </div>
          <div className="d-flex flex-row">
            {propertiesOfTemplate.map(p => {
              const logoId = `logo-${template.id}-${p.id}`;
              return (
                <div className="m-1" key={`property-${logoId}`} id={logoId}>
                  <PropertyLogo property={p} size="small" />
                  <UncontrolledTooltip placement="auto" target={logoId}>
                    {p.name}
                  </UncontrolledTooltip>
                </div>
              );
            })}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default DesignCard;
