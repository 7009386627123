import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import * as requests from './requests';
import { navigate } from 'gatsby';
import { schema as schema2, uiSchema as uiSchema2 } from '../../schemas/design';
import { Widget } from '../../form/formTemplate';
import FormWrapper from '../../form/formWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const schema1 = {
  type: 'object',
  title: 'Design',
  properties: {
    type: {
      type: 'string',
      title: 'Type of design',
      enum: ['STARTPAGE'],
      enumNames: ['Start page'],
      default: 'STARTPAGE',
    },
    properties: {
      title: 'Properties',
      type: 'string',
    },
    pageTag: {
      title: 'Page tag',
      type: 'string',
      description:
        'A tag that will be used for the referral to this page from within the template. You can use this to for example create pages with different languages and tag them accordingly, an English page can have the tag "lang-en", while a Korean one "lang-kr".',
    },
  },
  required: ['type', 'properties', 'pageTag'],
};

const uiSchema1 = {
  'ui:ObjectFieldTemplate': Widget,
  'properties': {
    'ui:widget': 'PropertiesListWithSelect',
  },
};

const formSteps = {
  properties: 'PROPERTIES',
  template: 'TEMPLATE',
};

const AddDesign = ({ baseRoute, propertiesList }) => {
  const subjectName = 'AddDesign';
  const propertyUrlTemplate = 'HOTEL_*_TOKEN'; // * - index, in the order of propertyIds

  const [formStep, setFormStep] = useState(formSteps.properties);
  const [propertyIds, setPropertyIds] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [formKey, setFormKey] = useState(0);

  const [createTemplate, { loading, error }] = useMutation(
    requests.CREATE_STARTPAGE_TEMPLATE,
    {
      onCompleted: () => {
        navigate(baseRoute, { replace: true });
      },
    },
  );

  const onSubmit1 = data => {
    const newPropertyIds = data.properties.split(',').map(id => +id);
    const selectedProperties = propertiesList.filter(p => {
      return newPropertyIds.includes(p.id);
    });
    setKeywords(
      selectedProperties.map((p, i) => ({
        title: p.name,
        value: propertyUrlTemplate.split('*').join(i + 1),
      })),
    );
    setPropertyIds(newPropertyIds);
    setFormStep(formSteps.template);
    setFormKey(k => k + 1);
  };

  const onSubmit2 = data => {
    createTemplate({
      variables: {
        propertyIds,
        pageTag: data.pageTag,
        template: data.template,
      },
    });
  };

  let formData;
  let schema;
  let uiSchema;
  let onSubmit;
  let SubmitButtonContent;
  if (formStep === formSteps.properties) {
    schema = schema1;
    uiSchema = uiSchema1;
    onSubmit = onSubmit1;
    formData = {
      properties: '',
      pageTag: '',
    };
    SubmitButtonContent = () => (
      <>
        Continue
        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
      </>
    );
  }
  if (formStep === formSteps.template) {
    schema = schema2;
    uiSchema = uiSchema2;
    onSubmit = onSubmit2;
    formData = {
      properties: propertyIds.join(','),
      // TODO: добавить дефолтные шаблоны на разное число отелей
      template: '',
    };
    SubmitButtonContent = () => (
      <>
        <FontAwesomeIcon icon={faSave} className="mr-2" />
        Add design
      </>
    );
  }

  return (
    <div className="px-2 py-4">
      <FormWrapper
        formData={formData}
        subjectName={subjectName}
        loading={loading}
        schemaSource={schema}
        uiSchemaSource={uiSchema}
        onSubmit={onSubmit}
        formKey={formKey}
        formContext={{
          loading,
          error,
          propertiesList,
          keywords,
          SubmitButtonContent,
        }}
        children={' '}
      />
    </div>
  );
};

export default AddDesign;
