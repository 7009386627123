import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

const PropertySelect = ({ propertyList, onSelect, direction, clear }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <div className="d-flex p-2">
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
        <DropdownToggle caret>Select property</DropdownToggle>
        <DropdownMenu
          modifiers={{
            setMaxHeight: {
              enabled: true,
              order: 890,
              fn: data => {
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    overflow: 'auto',
                    maxHeight: 270,
                  },
                };
              },
            },
          }}
        >
          <DropdownItem onClick={clear}>All</DropdownItem>
          <DropdownItem divider />
          {propertyList.map((p, i) => (
            <DropdownItem key={`${p.id}-${i}`} onClick={() => onSelect(p.id)}>
              {p.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default PropertySelect;
