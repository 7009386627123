import React from 'react';
import Layout from '../components/containers/layout';
import DesignsList from '../components/designs';

const Designs = () => {
  if (typeof window === 'undefined') return null;

  return (
    <Layout>
      <DesignsList />
    </Layout>
  );
};

export default Designs;
