import { Widget } from '../form/formTemplate';

export const schema = {
  type: 'object',
  title: 'Design',
  properties: {
    type: {
      type: 'string',
      title: 'Type of design',
      enum: ['STARTPAGE'],
      enumNames: ['Start page'],
      default: 'STARTPAGE',
      disabled: true,
    },
    properties: {
      title: 'Properties',
      type: 'string',
    },
    pageTag: {
      title: 'Page tag',
      type: 'string',
      description:
        'A tag that will be used for the referral to this page from within the template. You can use this to for example create pages with different languages and tag them accordingly, an English page can have the tag "lang-en", while a Korean one "lang-kr".',
    },
    template: {
      title: 'Template',
      type: 'string',
      description:
        'To reference another page use the following 2 data attributes <code>data-type="page" data-tag="my-tag"</code> in your desired DOM element<br>Here\'s an example button that would switch to a startpage with the tag <code>lang-en</code><br> <pre><code>&#60;button data-type="page" data-tag="lang-en"&#62;English&#60;/button&#62;</code></pre>To proceed to search bookings page, add an element with <code>data-type="check-in"</code>.',
    },
  },
};

export const uiSchema = {
  'ui:ObjectFieldTemplate': Widget,
  'type': {
    'ui:disabled': true,
  },
  'template': {
    'ui:widget': 'PageTemplateEditor',
  },
  'properties': {
    'ui:widget': 'PropertiesListWithSelect',
    'ui:disabled': true,
  },
  'pageTag': {
    'ui:disabled': true,
  },
};
