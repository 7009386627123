import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Spinner,
  Alert,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import ErrorLine from '../components/containers/errorLine';
import '../styles/formTemplate.css';
import { AbilityContext } from '../auth/abilityContext';
import DeleteButton from '../components/deleteButton';

export const Widget = props => {
  const ability = useContext(AbilityContext);
  const {
    loading,
    success,
    error,
    subjectName,
    SubmitButtonContent = () => (
      <>
        <FontAwesomeIcon icon={faSave} className="mr-2" />
        Save data
      </>
    ),
    onDelete,
  } = props.formContext || {};
  const [feedback, setFeedback] = useState(success || false);

  useEffect(() => {
    let timeout;
    if (feedback) {
      timeout = setTimeout(() => {
        setFeedback(false);
      }, 5000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [feedback]);

  return (
    <Card>
      <CardHeader>{props.title}</CardHeader>
      <CardBody className="pb-1">
        {props.description}
        {props.properties.map(element => (
          <div className="property-wrapper" key={`widget-${element.name}`}>
            {element.content}
          </div>
        ))}
        {error && <ErrorLine error={error} />}
        <Alert isOpen={feedback} toggle={() => setFeedback(false)}>
          Data has been saved
        </Alert>
      </CardBody>
      <CardFooter>
        {ability.can('update', subjectName) && (
          <Button
            className="mr-2"
            type="submit"
            color="success"
            disabled={props.disabled}
          >
            {loading ? (
              <Spinner color="light" size="sm" className="mr-2" />
            ) : (
              <SubmitButtonContent />
            )}
          </Button>
        )}
        {ability.can('delete', subjectName) && onDelete && (
          <DeleteButton
            subjectName={subjectName}
            deleteFunction={onDelete}
            deletingStatus={
              <div>
                {loading && <Spinner color="secondary" />}
                {error && <ErrorLine error={error} />}
              </div>
            }
          />
        )}
      </CardFooter>
    </Card>
  );
};
