import gqlTag from 'graphql-tag';

export const GET_TEMPLATES = gqlTag`
  query PropertiesList {
    propertiesList {
      id
      name
      propertySettings {
        id
        logo
      }
    }
    startpageTemplatesList {
      id
      userId
      template
      propertyIds
      pageTag
    }
  }
`;

export const GET_USER_TEMPLATES = gqlTag`
  query usersPropertiesList($userId: Int!) {
    usersPropertiesList(condition: { userId: $userId }) {
      property {
        id
        name
        propertySettings {
          id
          logo
        }
      }
    }
    startpageTemplatesList {
      id
      userId
      template
      propertyIds
      pageTag
    }
  }
`;

export const GET_STARTPAGE_TEMPLATE = gqlTag`
  query StartpageTemplate($templateId: Int!) {
    startpageTemplate(id: $templateId) {
      id
      userId
      template
      propertyIds
      pageTag
    }
  }
`;

export const UPDATE_STARTPAGE_TEMPLATE = gqlTag`
  mutation UpdateStartpageTemplate($templateId: Int! $template: String!) {
    updateStartpageTemplate(
      input: {
        patch: {template: $template}, 
        id: $templateId
      }) {
      startpageTemplate {
        id
        template
        propertyIds
      }
    }
  }
`;

export const CREATE_STARTPAGE_TEMPLATE = gqlTag`
  mutation CreateStartpageTemplate($template: String! $propertyIds: [Int]!, $pageTag: String!) {
    createStartpageTemplate(
      input: {
        startpageTemplate: {
          template: $template
          propertyIds:$propertyIds
          pageTag: $pageTag
        },
      }) {
      startpageTemplate {
        id
        template
        propertyIds
        pageTag
      }
    }
  }
`;

export const DELETE_STARTPAGE_TEMPLATE = gqlTag`
  mutation DeleteStartpageTemplate($templateId: Int!) {
    deleteStartpageTemplate(input: {id: $templateId}) {
      startpageTemplate {
        id
      }
    }
  }
`;
