import React from 'react';
import { Link } from 'gatsby';

const AddDesignCard = () => {
  return (
    <div className="col-sd-12 col-md-6 col-lg-4 col-xl-3 p-0">
      <div
        className="add-property border-bottom border-right"
        style={{ height: '100%', minHeight: '80px' }}
      >
        <Link to={'/designs/add'}>
          <div
            className="d-flex flex-row align-items-center justify-content-center property-text"
            style={{ height: '100%' }}
          >
            Add design
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AddDesignCard;
